(function(){
    'use strict';

    angular.module('aerosApp')
        .service('LinkmapService', LinkmapService);

    LinkmapService.$inject = ['UnitsMeasurementService'];

    function LinkmapService(UnitsMeasurementService) {
        this.getLocationPrecision = function getLocationPrecision(unit) {
            switch(unit) {
                case UnitsMeasurementService.KILOMETER:
                case UnitsMeasurementService.KILOFEET:
                case UnitsMeasurementService.MILE:
                    return 3;
            }

            return 1;
        }
    }
})();
